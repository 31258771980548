<template>
  <div>
    <div class="d-flex justify-content-between">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mb-2"
        @click="gotoAddBanner"
      >
        Add Banner
      </b-button>
      <b-form-group
        label-for="banner_status"
        v-if="active_role == 'corporate'"
      >
        <b-form-checkbox
          v-model="banner_status"
          class="custom-control-primary mt-50"
          name="check-button"
          switch
          @change="changeStatusBanner"
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </b-form-checkbox>
      </b-form-group>
    </div>
    <b-row>
      <b-col cols="12" v-if="active_role == 'corporate'">
        <banner-corporate></banner-corporate>
      </b-col>
      <b-col cols="12" v-else-if="active_role == 'superadmin'">
        <banner-superadmin></banner-superadmin>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {
    BRow, BCol,
    BButton, BFormGroup, BFormCheckbox
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import BannerCorporate from './master/BannerCorporate.vue'
  import BannerSuperadmin from './master/BannerSuperadmin.vue'

  export default {
    components: {
      BRow, BCol,
      BButton, BFormGroup, BFormCheckbox,
      BannerCorporate, BannerSuperadmin
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        banner_status: false,
        active_role: null
      }
    },
		created() {
      this.checkAuth()
    },
		mounted() {
	    this.active_role = localStorage.getItem('role')
      this.getStatusBanner()
		}, 
    methods: {
      async getStatusBanner() {
        let cid = localStorage.getItem('client_id')
        const config = {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        }

        let dataAPI = await this.$axios.get(`${cid}/banner/config`, config)
        let config_banner = dataAPI.data.data
        
        this.banner_status = config_banner.status
      },
      
      changeStatusBanner() {
        let status = this.banner_status
        let cid = localStorage.getItem('client_id')
        const config = {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        }

        let data = {
          status: status
        }

        this.$axios.post(`${cid}/banner/config`, data, config)
      },
      gotoAddBanner() {
        this.$router.push({ path: '/banner/add' })
      }
    }
  }
</script>